import React from "react"
import greenEconomy from "../../images/greenEconomy.jpg"
import reasonEsg from "../../images/business-talks.jpg"
import greenJob from "../../images/group-business.jpg"
import importance from "../../images/importance.jpg"
import COSOICSR from "../../pdfs/COSO-ICSR-Report.pdf"
import UNDP_Climate from "../../pdfs/undp-the-climate-dictionary-v2.pdf"
export default function BuildingASustainableFuture(props) {
  return (
    <section className="container pt-5 pb-5 ignore-width-constraint">
      <div>
        <div>
          {/* <img className="wrap-img" src={importance} alt={``} /> */}
        </div>
        <p className="warp-img-text">
          The United Nations Development Programme (UNDP) releasing a climate
          dictionary is a valuable resource for enhancing understanding and
          communication about climate-related issues. Climate change and
          sustainable development are critical global challenges, and having a
          standardized set of definitions for key terms can promote clarity and
          effective dialogue among researchers, policymakers, and the general
          public. A few terms:
        </p>
        <ul>
          <li>
            The "blue economy" concept seeks to promote economic development,
            social inclusion, and the preservation or improvement of livelihoods
            while at the same time ensuring environmental sustainability of the
            oceans and coastal areas.
          </li>
          <li>
            Carbon removal is the process of removing greenhouse gas emissions
            from the atmosphere, through natural solutions such as reforestation
            and soil management or technological solutions like direct air
            capture and enhanced mineralization.
          </li>
          <li>
            Carbon footprint is a measure of the greenhouse gas emissions
            released into the atmosphere by a particular person, organization,
            product, or activity. A bigger carbon footprint means more emissions
            of carbon dioxide and methane, and therefore a bigger contribution
            to the climate crisis.
          </li>
          <li>
            Carbon markets are trading schemes that create financial incentives
            for activities that reduce or remove greenhouse gas emissions. In
            these schemes, emissions are quantified into carbon credits that can
            be bought and sold.
          </li>
          <li>
            Circular economy refers to models of production and consumption that
            minimize waste and reduce pollution, promote sustainable uses of
            natural resources, and help regenerate nature.
          </li>
        </ul>
        <a
          href={UNDP_Climate}
          target="_blank"
          className="btn learn-more-btn my-4"
          style={{ marginRight: "5px" }}
        >
          CLICK TO DOWNLOAD THE CLIMATE DICTIONARY
        </a>
      </div>
    </section>
  )
}
